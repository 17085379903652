var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"show-empty":"","responsive":true,"hover":true,"bordered":true,"fields":_vm.fields,"items":_vm.items,"busy":_vm.submitting},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticStyle:{"height":"30px"}},[_c('div',{staticClass:"h4 text-center position-loading"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Завантаження...")])],1)])]},proxy:true},{key:"cell(amount)",fn:function(data){return [_c('b',[_vm._v(_vm._s(data.value))])]}},{key:"cell(original_amount)",fn:function(data){return [_c('b',[_vm._v(_vm._s(data.value))])]}},{key:"cell(id)",fn:function(data){return [(data.item.payee_counterpart_id)?_c('b-link',{on:{"click":function($event){return _vm.viewReceipt(data.value)}}},[_c('b-icon-printer')],1):_vm._e(),_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(payer)",fn:function(data){return [(data.item.payer_counterpart_id)?_c('b-link',{attrs:{"target":"_blank","to":{
          name: 'counterpart',
          params: { id: data.item.payer_counterpart_id },
        }}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(payer_fullname)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.payer_last_name)+" "+_vm._s(data.item.payer_first_name)+" "+_vm._s(data.item.payer_middle_name)+" ")]}},{key:"cell(payer_contract_number)",fn:function(data){return [(data.item.payer_contract_id)?_c('b-link',{attrs:{"target":"_blank","to":{
          name: 'contract',
          params: { id: data.item.payer_contract_id },
        }}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(payee)",fn:function(data){return [(data.item.payee_counterpart_id)?_c('b-link',{attrs:{"target":"_blank","to":{
          name: 'counterpart',
          params: { id: data.item.payee_counterpart_id },
        }}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(payee_fullname)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.payee_last_name)+" "+_vm._s(data.item.payee_first_name)+" "+_vm._s(data.item.payee_middle_name)+" ")]}},{key:"cell(payee_contract_number)",fn:function(data){return [(data.item.payee_contract_id)?_c('b-link',{attrs:{"target":"_blank","to":{
          name: 'contract',
          params: { id: data.item.payee_contract_id },
        }}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(payer_card_hash)",fn:function(data){return [(data.item.payer_card_hash)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(data.item.payer_card_hash),expression:"data.item.payer_card_hash"}],staticClass:"cursor-pointer",attrs:{"data-text":data.item.payer_card_hash,"variant":"light"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyItem.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.makeShortenedHash(data.item.payer_card_hash))+" ")]):_vm._e()]}},{key:"cell(payee_card_hash)",fn:function(data){return [(data.item.payee_card_hash)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(data.item.payee_card_hash),expression:"data.item.payee_card_hash"}],staticClass:"cursor-pointer",attrs:{"data-text":data.item.payee_card_hash,"variant":"light"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyItem.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.makeShortenedHash(data.item.payee_card_hash))+" ")]):_vm._e()]}},{key:"empty",fn:function(scope){return [_c('div',{staticStyle:{"height":"30px"}},[_c('div',{staticClass:"h4 text-center position-loading"},[_vm._v(" Операції не знайдено ")])])]}}])}),_c('b-modal',{attrs:{"id":"receipt","size":"xl","title":"Квитанція","ok-only":""}},[_c('b-embed',{ref:"receipt-src",attrs:{"type":"embed","src":_vm.receipt}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }