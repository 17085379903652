export default {
  methods: {
    getQueryFromFilter(query, filter) {
      Object.entries(filter).forEach(([key, filter]) => {
        if (
          filter.value !== null ||
          filter.value !== "" ||
          filter.value.length !== 0
        ) {
          if (filter.date === true) {
            query[key] = new Date(filter.value).toISOString();
          } else query[key] = filter.value;
        }

        if (
          filter.value === "" ||
          filter.value === null ||
          filter.value.length < 1
        ) {
          delete query[key];
        }
      });

      return query;
    },
    getFilterFromQuery(query, filter) {
      Object.entries(query).forEach(([key, parameter]) => {
        if (key in filter) {
          if (
            filter[key].type === "whereIn" ||
            filter[key].type === "whereNotIn"
          ) {
            filter[key].value = parameter.split(",");
          } else {
            if (filter[key].date === true) {
              filter[key].value = new Date(parameter);
            } else {
              filter[key].value = parameter;
            }
          }
        }
      });

      return filter;
    }
  }
};
