<template>
  <div v-if="Object.keys(errors).length > 0">
    <b-alert v-if="validationCodes.includes(errors.code)" show variant="danger">
      <h4 class="alert-heading">
        {{ message }}
      </h4>
      <li v-for="(error, index) in errors.description" :key="index">
        <span v-for="(e, i) in error" :key="i"> {{ e }} </span>
      </li>
    </b-alert>
    <b-alert v-else-if="errors.code === 504" show variant="danger">
      <h4 class="alert-heading">Термін очікування відповіді сплив</h4>
      <li>504 - Gateway Timeout</li>
    </b-alert>
    <b-alert v-else show variant="danger">
      <h4 class="alert-heading">
        Виникла невідома помилка роботи серверу, спробуйте оновити сторінку
      </h4>
      <li>{{ errors.code }} - {{ errors.description }}</li>
    </b-alert>
  </div>
</template>
<script>
export default {
  name: "ErrorAlert",
  props: {
    errors: {
      type: Object,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      validationCodes: [400, 406, 422],
    };
  },
};
</script>
