export default {
  methods: {
    setValidationState(errors, dirty, validated, valid, changed) {
      return errors.length > 0 ? false : dirty && changed ? true : null;
    },
    getChangedFields(validationFields, form) {
      const keys = Object.keys(validationFields).reduce((accumulator, key) => {
        if (validationFields[key].changed === true && key in form) {
          accumulator.push(key);
        }

        return accumulator;
      }, []);

      return Object.keys(form).reduce(
        (accumulator, key) => {
          if (keys.includes(key)) {
            accumulator[key] = form[key];
          }

          return accumulator;
        },
        { id: form.id }
      );
    }
  }
};
